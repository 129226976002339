import { Box, Text, VStack, Image, Button, Link } from "@chakra-ui/react";

import { LP_NETWORKS } from "../../constants/firebase";
import Skeleton from "react-loading-skeleton";

import { trackEvent } from "../../utils/firebase";

interface Props {
  label: string;
  icon: string;
  primaryCTAText: string;
  primaryCTAUrl: string;
  secondaryCTAText: string;
  secondaryCTAUrl: string;
  tvl: string | undefined;
  section?: string;
  tag?: string;
  isHeader?: boolean;
}

const NetworkCard = ({
  label,
  icon,
  primaryCTAText,
  primaryCTAUrl,
  secondaryCTAText,
  secondaryCTAUrl,
  tvl,
  section,
  tag,
  isHeader = false,
}: Props) => {
  const logEvent = (ctaText: string) => {
    trackEvent(LP_NETWORKS, {
      cta: section || "primary",
      name: label,
      cta_text: ctaText,
    });
  };
  return (
    <Box
      outline="none"
      bg="#E6F8EF"
      borderRadius={{ base: "10px", lg: "16px" }}
      pt={6}
      px={2}
      pb="0"
      minW={{ base: 104, lg: isHeader ? 176 : 205 }}
      maxH={{ base: 136, lg: 222 }}
      pos="relative"
      sx={{
        "&:hover": {
          bg: "radial-gradient(122.38% 69.59% at 50.3% 100%, #07C166 0%, #18C972 0.01%, #07A658 100%)",
          boxShadow: "0px 0px 32px rgba(20, 37, 40, 0.4)",
        },
        "&:hover p.titleNetwork": {
          color: "white",
        },
        "&:hover p.subTitleNetwork": {
          color: "#FCD7D2",
        },
        "&:hover .cta": {
          visibility: "visible",
          opacity: 1,
        },
        "&:hover img": {
          transform: "translateY(-60px) scale(0.5714)",
          filter: "blur(8px)",
          opacity: 0.3,
        },
      }}
      transition="all 0.3s ease-in-out"
      overflow="hidden"
    >
      <VStack
        gap={{ base: "32px", lg: "40px" }}
        textAlign="center"
        overflow="hidden"
      >
        <Box>
          <Text
            className="titleNetwork"
            fontSize={{ base: 14, lg: 24 }}
            fontWeight={600}
            color="textPrimary"
          >
            {label}
          </Text>
          <Text
            className="subTitleNetwork"
            fontSize={{ base: 10, lg: 16 }}
            fontWeight={600}
            color="textSecondary"
          >
            {tag ? (
              tag
            ) : tvl ? (
              `TVL : ${tvl}`
            ) : (
              <Skeleton style={{ backgroundColor: "#83d3ac" }} />
            )}
          </Text>
        </Box>
        <VStack
          className="cta"
          visibility="hidden"
          opacity={0}
          transition="all 0.6s ease"
          zIndex={1}
          pos="absolute"
          top="60%"
          transform="translateY(-25%)"
          transitionDelay="0.1s"
          transitionProperty="visibility, opacity"
        >
          {primaryCTAText && (
            <Button
              as="a"
              href={primaryCTAUrl}
              target="_blank"
              rel="canonical"
              bg="white !important"
              color="black !important"
              textStyle="p14"
              fontWeight={600}
              _hover={{ bg: "white" }}
              onClick={() => {
                logEvent(primaryCTAText);
              }}
            >
              {primaryCTAText}
            </Button>
          )}
          {secondaryCTAText && (
            <Link
              variant="secondary"
              href={secondaryCTAUrl}
              target="_blank"
              rel="canonical"
              color="white !important"
              borderColor="white !important"
              onClick={() => {
                logEvent(secondaryCTAText);
              }}
            >
              {secondaryCTAText}
            </Link>
          )}
        </VStack>
        <Image
          src={icon}
          boxSize={{ base: "86px", lg: "140px" }}
          alt={`${label}-icon`}
          transition="all 0.3s ease"
        />
      </VStack>
    </Box>
  );
};

export default NetworkCard;
